import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "登录",
    component: () => import("../views/common/Login.vue"),
  },
  {
    path: "/register",
    name: "注册",
    component: () => import("../views/common/Register.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/common/404.vue"),
  },

  {
    path: "/",
    name: "Manage",
    component: () => import("../views/common/Manage.vue"),
    children: [
      {
        path: "person",
        name: "个人信息",
        component: () => import("../views/common/Person.vue"),
      },
      {
        path: "password",
        name: "修改密码",
        component: () => import("../views/common/Password.vue"),
      },
      {
        path: "home",
        name: "主页",
        component: () => import("../views/common/Home.vue"),
      },
      {
        path: "file",
        name: "文件管理",
        component: () => import("../views/sys/FileManage.vue"),
      },
      {
          path: "file",
          name: "文件管理",
          component: () => import("../views/sys/FileManage.vue"),
        },
        {
          path: "menu",
          name: "菜单管理",
          component: () => import("../views/sys/MenuManage.vue"),
        },
        {
          path: "role",
          name: "角色管理",
          component: () => import("../views/sys/RoleManage.vue"),
        },
        {
          path: "user",
          name: "用户管理",
          component: () => import("../views/sys/UserManage.vue"),
        },
        {
          path: "setting",
          name: "系统设置",
          component: () => import("../views/sys/SysSettingManage.vue"),
        },
        {
          path: "log",
          name: "日志监控",
          component: () => import("../views/sys/LogMessageManage.vue"),
        },
        {
            path: "dictType",
            name: "字典数据",
            component: () => import("../views/sys/SysDictTypeManage.vue"),
          },
          {
            path: "example",
            name: "示例模块",
            component: () => import("../views/common/ExampleManage.vue"),
          },
          {
            path: "QuestionInformationManage",
            name: "示例模块",
            component: () => import("../views/sys/QuestionInformationManage.vue"),
          },
          {
            path: "VoiceChatManage",
            name: "示例模块",
            component: () => import("../views/sys/VoiceChatManage.vue"),
          },

   
    ]
},


  {
    path: "/front",
    name: "Front",
    component: () => import("../views/front/Front.vue"),
    children: [
      {
        path: "/frontHome",
        name: "主页",
        component: () => import("../views/front/Home.vue"),
      },
      {
        path: "/video",
        name: "视频",
        component: () => import("../views/front/Video.vue"),
      },
      {
        path: "/videoDetail",
        name: "视频详情",
        component: () => import("../views/front/VideoDetail.vue"),
      },
      {
        path: "/frontArticle",
        name: "文章列表",
        component: () => import("../views/front/Article.vue"),
      },
      {
        path: "/articleDetail",
        name: "文章列表",
        component: () => import("../views/front/ArticleDetail.vue"),
      },
      {
        path: "/part1",
        name: "文章列表",
        component: () => import("../views/front/part1.vue"),
      },
      {
        path: "/part2",
        name: "文章列表",
        component: () => import("../views/front/part2.vue"),
      },
      {
        path: "/part3",
        name: "文章列表",
        component: () => import("../views/front/part3.vue"),
      },
      {
        path: "/part4",
        name: "文章列表",
        component: () => import("../views/front/part4.vue"),
      },
      {
        path: "/part5",
        name: "文章列表",
        component: () => import("../views/front/part5.vue"),
      },
      {
        path: "/part6",
        name: "文章列表",
        component: () => import("../views/front/part6.vue"),
      },
      {
        path: "/part7",
        name: "文章列表",
        component: () => import("../views/front/part7.vue"),
      },
      {
        path: "/part8",
        name: "文章列表",
        component: () => import("../views/front/part8.vue"),
      },
      {
        path: "/part9",
        name: "文章列表",
        component: () => import("../views/front/part9.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 提供一个重置路由的方法
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
  });
};


// // 注意：刷新页面会导致页面路由重置
// export const setRoutes = () => {
//   const storeMenus = localStorage.getItem("menus");
//   if (storeMenus) {
//     // 获取当前的路由对象名称数组
//     const currentRouteNames = router.getRoutes().map((v) => v.name);
//     if (!currentRouteNames.includes("Manage")) {
//       // 拼装动态路由
//       const manageRoute = {
//         path: "/",
//         name: "Manage",
//         component: () => import("../views/common/Manage.vue"),
//         redirect: "/home",
//         children: [
//           {
//             path: "person",
//             name: "个人信息",
//             component: () => import("../views/common/Person.vue"),
//           },
//           {
//             path: "password",
//             name: "修改密码",
//             component: () => import("../views/common/Password.vue"),
//           },
//         ],
//       };
//       const menus = JSON.parse(storeMenus);
//       menus.forEach((item) => {
//         if (item.path) {
//           // 当且仅当path不为空的时候才去设置路由
//           let itemMenu = {
//             path: item.path.replace("/", ""),
//             name: item.name,
//             component: () => import("../views/" + item.pagePath + ".vue"),
//           };
//           manageRoute.children.push(itemMenu);
//         } else if (item.children.length) {
//           item.children.forEach((item) => {
//             if (item.path) {
//               let itemMenu = {
//                 path: item.path.replace("/", ""),
//                 name: item.name,
//                 component: () => import("../views/" + item.pagePath + ".vue"),
//               };
//               manageRoute.children.push(itemMenu);
//             }
//           });
//         }
//       });
//       // 动态添加到现在的路由对象中去
//       router.addRoute(manageRoute);
//     }
//   }
// };

// 重置我就再set一次路由
// setRoutes();

// 重置我就再set一次路由
// setRoutes();

router.beforeEach((to, from, next) => {
    
    localStorage.setItem("currentPathName", to.name); //设置当前路由名称
    store.commit("setPath"); //触发store的数据更新
    //未找到路由的情况
  //   if (!to.matched.length) {
  //     const storeMenus = localStorage.getItem("menus");
  //     if (storeMenus) {
  //       next("/404"); //404
  //     } else {
  //       next("/login"); // 跳回登录页面
  //     }
  //   }
    next(); //放行路由
  });
  
  export default router;
